import { template as template_328342010d6c4a299b653de7053fbc2c } from "@ember/template-compiler";
const WelcomeHeader = template_328342010d6c4a299b653de7053fbc2c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
