import { template as template_f767835fa0a94ccc86fe402f9eb70574 } from "@ember/template-compiler";
const FKText = template_f767835fa0a94ccc86fe402f9eb70574(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
